import { authAxios } from "~/utils";
import getEnv from "./env";

type VendorPortalAxios = Pick<
  typeof authAxios,
  "post" | "get" | "delete" | "put" | "patch"
>;

export const axios: VendorPortalAxios = new Proxy(authAxios, {
  get(target, requestType) {
    const method = (target as any)[requestType];
    return (...args: any) => {
      const [url, ...config] = args;

      if (["post", "put", "patch"].includes(requestType as string)) {
        const [data, options = {}] = config;
        return method.call(
          this,
          url,
          data,
          Object.assign(options, {
            baseURL: options.baseURL || getEnv().REACT_APP_VP_API_BASE_URL,
          }),
        );
      }
      const [data] = config;

      return method.call(this, url, {
        baseURL: data?.baseURL || getEnv().REACT_APP_VP_API_BASE_URL,
        ...Object.entries(config).reduce((options, [, option]) => {
          return Object.assign(options, option);
        }, {}),
      });
    };
  },
});
